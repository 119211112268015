@font-face {
  font-family: 'social-icons';
  src:
    url('fonts/social-icons.ttf?o9q9ei') format('truetype'),
    url('fonts/social-icons.woff?o9q9ei') format('woff'),
    url('fonts/social-icons.svg?o9q9ei#social-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.si {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'social-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.si-trello:before {
  content: "\e907";
}
.si-facebook:before {
  content: "\ea90";
}
.si-whatsapp:before {
  content: "\ea93";
}
.si-twitter:before {
  content: "\ea96";
}
.si-linkedin:before {
  content: "\eaca";
}
.si-yahoo:before {
  content: "\e900";
}
.si-tumblr:before {
  content: "\f173";
}
.si-telegram:before {
  content: "\e904";
}
.si-gmail:before {
  content: "\e905";
}
.si-pinterest:before {
  content: "\e901";
}
.si-google-plus:before {
  content: "\e902";
}
.si-vk:before {
  content: "\e903";
}
.si-odnoklassniki:before {
  content: "\e906";
}
